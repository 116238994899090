import type { FragmentType } from '.gql/fragment-masking'
import type { RichTextBlockEntry_FragmentFragment } from '.gql/graphql'
import { useFragment as getFragment } from '.gql/fragment-masking'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import type { Options } from '@contentful/rich-text-react-renderer'
import type { Document } from '@contentful/rich-text-types'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { ListItem, UnorderedList } from '@/components/list-item'
import { graphql } from '.gql/gql'
import Link from 'next/link'

export const ComposeComponentVideoAndAdjacentText_Fragment = graphql(
  /* GraphQL */ `
    fragment ComposeComponentVideoAndAdjacentText_Fragment on ComposeComponentVideoAndAdjacentText {
      __typename
      sys {
        id
      }
      videoAlignment
      video {
        url
        title
        description
      }
      description {
        json
        links {
          assets {
            block {
              sys {
                id
              }
              ... on Asset {
                title
                description
                url
                width
                height
              }
            }
          }
        }
      }
    }
  `,
)

export type ComposeComponentVideoAndAdjacentTextFragment = FragmentType<
  typeof ComposeComponentVideoAndAdjacentText_Fragment
>

export const ComposeComponentText_Fragment = graphql(/* GraphQL */ `
  fragment ComposeComponentText_Fragment on ComposeComponentText {
    __typename
    sys {
      id
    }
    text {
      json
    }
    title
  }
`)

export type ComposeComponentTextFragment = FragmentType<
  typeof ComposeComponentText_Fragment
>

export const ComposeComponentImageAndAdjacentText_Fragment = graphql(
  /* GraphQL */ `
    fragment ComposeComponentImageAndAdjacentText_Fragment on ComposeComponentImageAndAdjacentText {
      __typename
      sys {
        id
      }
      imageAlignment
      image {
        url
        title
        description
      }
      text {
        json
      }
    }
  `,
)

export type ComposeComponentImageAndAdjacentTextFragment = FragmentType<
  typeof ComposeComponentImageAndAdjacentText_Fragment
>

export const ComposeComponentTextList_Fragment = graphql(/* GraphQL */ `
  fragment ComposeComponentTextList_Fragment on ComposeComponentTextList {
    __typename
    sys {
      id
    }
    textItemsCollection(limit: 20) {
      __typename
      items {
        sys {
          id
        }
        __typename
        ...ComposeComponentText_Fragment
      }
    }
  }
`)

export const ComposeComponentImage_Fragment = graphql(/* GraphQL */ `
  fragment ComposeComponentImage_Fragment on ComposeComponentImage {
    altText
    imageAsset {
      url
      width
      height
    }
  }
`)

export type ComposeComponentImageFragment = FragmentType<
  typeof ComposeComponentImage_Fragment
>

export type ComposeComponentTextListFragment = FragmentType<
  typeof ComposeComponentTextList_Fragment
>

export const RichTextBlockEntry_Fragment = graphql(/* GraphQL */ `
  fragment RichTextBlockEntry_Fragment on Entry {
    __typename
    sys {
      id
    }
    ... on ComposeComponentText {
      ...ComposeComponentText_Fragment
    }
    ... on ComposeComponentImageAndAdjacentText {
      ...ComposeComponentImageAndAdjacentText_Fragment
    }
    ... on ComposeComponentVideoAndAdjacentText {
      ...ComposeComponentVideoAndAdjacentText_Fragment
    }
    ... on ComposeComponentTextList {
      ...ComposeComponentTextList_Fragment
    }
  }
`)

export interface LinkNode {
  data?: {
    target?: {
      sys?: {
        id?: string
      }
    }
  }
}

export function createEntryMap(
  block: (RichTextBlockEntry_FragmentFragment | null)[],
) {
  const entryMap = new Map<string, RichTextBlockEntry_FragmentFragment>()

  for (const entry of block) {
    if (entry?.sys) {
      entryMap.set(entry.sys.id, entry)
    }
  }

  return entryMap
}

export const alignmentClasses = {
  'left-align': 'md:flex-row',
  'right-align': 'md:flex-row-reverse',
}

export const getImageConfig = ({
  query,
}: {
  query: ComposeComponentImageAndAdjacentTextFragment
}) => {
  const { image, imageAlignment, text } = getFragment(
    ComposeComponentImageAndAdjacentText_Fragment,
    query,
  )
  const alignment =
    alignmentClasses[imageAlignment as keyof typeof alignmentClasses]

  const src = image?.url ?? ''
  const description = image?.description ?? 'image beside text'

  return {
    src,
    description,
    alignment,
    text,
  }
}

/**
 * Overrides default list styling so nested lists are styled differently.
 * Only supports one level of nesting.
 * To support more levels of nesting, pass an object similar to this one as a renderOptions prop on the ListItem component on line 181.
 */
export const listRenderOptions: Options = {
  renderNode: {
    [BLOCKS.UL_LIST]: (node, _children) => (
      <UnorderedList
        renderOptions={{
          renderNode: {
            [BLOCKS.LIST_ITEM]: (nestedChildNode, _nestedChildren) => (
              <ListItem
                listClassNames="list-circle ml-4"
                node={nestedChildNode}
              />
            ),
          },
        }}
        listClassNames="mt-2 space-y-2 pl-4"
        node={node}
      />
    ),
  },
}

export const baseRenderOptions: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="font-bold">{text}</span>,
  },
  renderNode: {
    [BLOCKS.LIST_ITEM]: (node, _children) => (
      <ListItem renderOptions={listRenderOptions} node={node} />
    ),
    [BLOCKS.OL_LIST]: (node, _children) => (
      <ol>
        {documentToReactComponents(node as Document, {
          renderNode: {
            [BLOCKS.LIST_ITEM]: (nestedChildNode, _nestedChildren) => (
              <ListItem
                listClassNames="w-full list-outside list-decimal"
                node={nestedChildNode}
              />
            ),
            [BLOCKS.PARAGRAPH]: (_node, children) => children,
            [BLOCKS.OL_LIST]: (_node, children) => children,
          },
        })}
      </ol>
    ),
    // Contentful returns an empty p tag on last line of text, we don't want to render that
    [BLOCKS.PARAGRAPH]: (_node, children) => {
      const isEmptyChildren = children?.toString().trim() === ''
      if (isEmptyChildren) return null
      return <p>{children}</p>
    },
    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={(node.data['uri'] as string | undefined) ?? ''}>
        {children}
      </Link>
    ),
  },
}

export function ComponentRenderer({
  document,
  options,
  classNames,
}: {
  document: Document
  options?: Options
  classNames?: string
}) {
  const renderOverrides = options ?? {}

  const renderOptions: Options = {
    renderMark: {
      ...baseRenderOptions.renderMark,
      ...renderOverrides.renderMark,
    },
    renderNode: {
      ...baseRenderOptions.renderNode,
      ...renderOverrides.renderNode,
    },
  }

  return (
    <div className={classNames}>
      {documentToReactComponents(document, renderOptions)}
    </div>
  )
}

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import type { Options, RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import type { Block, Document, Inline } from '@contentful/rich-text-types'
import type { PropsWithChildren } from 'react'

export interface ListItemProps extends PropsWithChildren {
  node?: Block | Inline
  renderOptions?: Options
  listClassNames?: string
}

/**
 * Find the child of the type, and return the children of that, without any wrapping markup.
 * List Items are wrapped in a <p> tag by default from contentful. This removes that.
 * @see https://github.com/contentful/rich-text/issues/126#issuecomment-636926522
 */
const defaultListItemOptions: RenderNode = {
  [BLOCKS.PARAGRAPH]: (_node, children) => children,
  [BLOCKS.LIST_ITEM]: (_node, children) => children,
}

export function ListItem({
  node,
  renderOptions,
  listClassNames,
  children,
}: ListItemProps) {
  if (children) {
    return <li className={listClassNames}>{children}</li>
  }

  return (
    <li className={listClassNames}>
      {documentToReactComponents(node as Document, {
        renderMark: renderOptions?.renderMark ?? {},
        renderNode: renderOptions
          ? { ...renderOptions.renderNode, ...defaultListItemOptions }
          : { ...defaultListItemOptions },
      })}
    </li>
  )
}

/**
 * Find the child of the type, and return the children of that, without any wrapping markup.
 * Nested unordered lists are wrapped in an additional <ul> tag. This removes that.
 */
const defaultUnorderedListOptions: RenderNode = {
  [BLOCKS.PARAGRAPH]: (_node, children) => children,
  [BLOCKS.UL_LIST]: (_node, children) => children,
}

export function UnorderedList({
  node,
  renderOptions,
  listClassNames,
  children,
}: ListItemProps) {
  if (children) {
    return <ul className={listClassNames}>{children}</ul>
  }
  return (
    <ul className={listClassNames}>
      {documentToReactComponents(node as Document, {
        renderMark: renderOptions?.renderMark ?? {},
        renderNode: renderOptions
          ? { ...renderOptions.renderNode, ...defaultUnorderedListOptions }
          : { ...defaultUnorderedListOptions },
      })}
    </ul>
  )
}

import { useFragment } from '.gql/fragment-masking'
import type { FragmentType } from '.gql/fragment-masking'
import { graphql } from '.gql/gql'
import type { Document } from '@contentful/rich-text-types'
import Image from 'next/image'
import { ComponentRenderer } from 'src/lib/rich-text-render-helpers'
import { twMerge } from 'tailwind-merge'

export const ProductOwnersBlock_Fragment = graphql(/* GraphQL */ `
  fragment ProductOwnersBlock_Fragment on ComposeComponentImageAndAdjacentText {
    sys {
      id
    }
    __typename
    image {
      url
      title
    }
    imageAlignment
    text {
      json
    }
  }
`)

export type ProductOwnersBlock = FragmentType<
  typeof ProductOwnersBlock_Fragment
>

interface ProductOwnersBlockProps {
  query: ProductOwnersBlock
}

const imageAlignmentClasses = {
  'left-align': 'md:flex-row',
  'right-align': 'md:flex-row-reverse',
}

export function ProductOwnersBlock({ query }: ProductOwnersBlockProps) {
  const { image, imageAlignment, text } = useFragment(
    ProductOwnersBlock_Fragment,
    query,
  )

  const alignment =
    imageAlignmentClasses[imageAlignment as keyof typeof imageAlignmentClasses]

  return (
    <div className="bg-wl-blue-malibu-400 px-2 py-6 text-white prose-p:text-center prose-img:m-0">
      <div
        className={twMerge(
          'content-container flex-col items-center px-6 md:space-x-4',
          alignment,
        )}
      >
        <div className="my-auto max-sm:mb-8 md:mx-8 md:min-w-[120px]">
          {image?.url && (
            <Image
              src={image.url}
              height={120}
              width={120}
              alt={image.title ?? 'mission statement'}
            />
          )}
        </div>
        <ComponentRenderer
          document={text?.json as Document}
          classNames="text-shadow-lg"
        />
      </div>
    </div>
  )
}

import PageMetadata from '@/components/metadata'
import Page from '@/components/page'
import {
  ComingSoonBlock,
  ProductOwnersBlock,
  ProductSummaryBlock,
  StudentOutcomesBlock,
  TeacherSupportBlock,
  VideoCTA,
} from '@/components/home-page'
import type { InferGetServerSidePropsType } from 'next'
import { getFooter, graphQLClient } from 'src/lib/graphql-contentful-api'
import { graphql } from '.gql/gql'

export const WritingLegendsIndexPage_Query = graphql(/* GraphQL */ `
  query WritingLegendsMarketingSiteIndexPage($id: String!, $locale: String) {
    writingLegendsMarketingSiteIndexPage(id: $id, locale: $locale) {
      sys {
        id
      }
      seoPageTitle
      seoPageDescription
      seoKeywords
      email
      phoneNumber
      summaryBlock {
        ...ProductSummaryBlock_Fragment
      }
      videoBlock {
        ...VideoBlock_Fragment
      }
      studentOutcomesBlock {
        ...StudentOutcomesBlock_Fragment
      }
      productOwnersBlock {
        ...ProductOwnersBlock_Fragment
      }
      teacherSupportBlock {
        ...TeacherSupportStatementBlock_Fragment
      }
    }
  }
`)

export interface ServerSideProps {
  query: {
    country: string
    locale: string
  }
  req: {
    headers: {
      host: string
    }
  }
}

export const getServerSideProps = async ({ query, req }: ServerSideProps) => {
  const [{ data: footerData }, { data: indexData }] = await Promise.all([
    getFooter(query.locale),
    graphQLClient.query(WritingLegendsIndexPage_Query, {
      id: '3NM55gHCEqOgXDMx228VNh',
      locale: query.locale,
    }),
  ])

  if (
    !indexData?.writingLegendsMarketingSiteIndexPage ||
    !footerData?.composeComponentWlFooterBlock
  ) {
    return { notFound: true }
  }

  return {
    props: {
      footerProps: footerData.composeComponentWlFooterBlock,
      indexData: indexData.writingLegendsMarketingSiteIndexPage,
      country: query.country,
      domain: req.headers.host,
    },
  }
}

export default function Home({
  footerProps,
  indexData,
  country,
  domain,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  country = decodeURIComponent(country)

  const {
    seoPageTitle,
    seoPageDescription,
    seoKeywords,
    videoBlock,
    productOwnersBlock,
    studentOutcomesBlock,
    teacherSupportBlock,
    summaryBlock: productSummaryBlock,
  } = indexData

  return (
    <>
      <PageMetadata
        description={seoPageDescription}
        keywords={seoKeywords}
        title={seoPageTitle}
      />
      <Page footerData={footerProps} country={country} domain={domain}>
        {videoBlock && <VideoCTA query={videoBlock} />}
        {studentOutcomesBlock && (
          <StudentOutcomesBlock query={studentOutcomesBlock} />
        )}
        {productOwnersBlock && (
          <ProductOwnersBlock
            query={productOwnersBlock as ProductOwnersBlock}
          />
        )}
        {teacherSupportBlock && (
          <TeacherSupportBlock query={teacherSupportBlock} />
        )}
        <ComingSoonBlock />
        {productSummaryBlock && (
          <ProductSummaryBlock query={productSummaryBlock} />
        )}
      </Page>
    </>
  )
}

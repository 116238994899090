import { useFragment } from '.gql/fragment-masking'
import type { FragmentType } from '.gql/fragment-masking'
import { graphql } from '.gql/gql'
import type { Document } from '@contentful/rich-text-types'
import { ComponentRenderer } from 'src/lib/rich-text-render-helpers'

export const ProductSummaryBlock_Fragment = graphql(/* GraphQL */ `
  fragment ProductSummaryBlock_Fragment on WritingLegendsMarketingSiteIndexPageSummaryBlock {
    __typename
    json
    links {
      entries {
        block {
          sys {
            id
          }
          ... on ComposeComponentVideoAndAdjacentText {
            ...ComposeComponentVideoAndAdjacentText_Fragment
          }
          ... on ComposeComponentImageAndAdjacentText {
            ...ComposeComponentImageAndAdjacentText_Fragment
          }
        }
      }
    }
  }
`)

export type ProductSummaryBlock = FragmentType<
  typeof ProductSummaryBlock_Fragment
>

interface ProductSummaryBlockProps {
  query: ProductSummaryBlock
}
export function ProductSummaryBlock({ query }: ProductSummaryBlockProps) {
  const data = useFragment(ProductSummaryBlock_Fragment, query)

  return (
    <div className="mx-auto py-8 text-left">
      <ComponentRenderer
        document={data.json as Document}
        classNames="content-container mb-6 flex-col space-y-8 px-8 max-sm:px-8"
      />
    </div>
  )
}

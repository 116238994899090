import Link from 'next/link'

export function ComingSoonBlock() {
  return (
    <div className="mx-auto flex w-full flex-row justify-center bg-wl-orange-500 py-8">
      <Link href="/contact-us" className="rounded-md bg-wl-blue-malibu-400 px-4 py-2 drop-shadow-md">
        <span className="font-bold text-white text-shadow-lg">Start your FREE TRIAL today!</span>
      </Link>
    </div>
  )
}

import { BLOCKS } from '@contentful/rich-text-types'
import type { Document } from '@contentful/rich-text-types'
import type { Options } from '@contentful/rich-text-react-renderer'
import { useFragment } from '.gql/fragment-masking'
import type { FragmentType } from '.gql/fragment-masking'
import type { LinkNode } from 'src/lib/rich-text-render-helpers'
import {
  ComponentRenderer,
  createEntryMap,
  getImageConfig,
} from 'src/lib/rich-text-render-helpers'
import Image from 'next/image'
import { graphql } from '.gql/gql'

export const StudentOutcomesBlock_Fragment = graphql(/* GraphQL */ `
  fragment StudentOutcomesBlock_Fragment on WritingLegendsMarketingSiteIndexPageStudentOutcomesBlock {
    __typename
    json
    links {
      entries {
        block {
          sys {
            id
          }
          ... on ComposeComponentVideoAndAdjacentText {
            ...ComposeComponentVideoAndAdjacentText_Fragment
          }
          ... on ComposeComponentImageAndAdjacentText {
            ...ComposeComponentImageAndAdjacentText_Fragment
          }
        }
      }
    }
  }
`)

export type StudentOutcomesBlock = FragmentType<
  typeof StudentOutcomesBlock_Fragment
>

interface StudentOutcomesBlockProps {
  query: StudentOutcomesBlock
}

export function StudentOutcomesBlock({ query }: StudentOutcomesBlockProps) {
  const data = useFragment(StudentOutcomesBlock_Fragment, query)
  const entryMap = createEntryMap(data.links.entries.block)

  const renderOptions: Options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node: LinkNode) => {
        const id = node.data?.target?.sys?.id ?? ''
        const entry = entryMap.get(id)

        if (entry?.__typename !== 'ComposeComponentImageAndAdjacentText') return

        const { alignment, description, text, src } = getImageConfig({
          query: entry,
        })

        return (
          <div className={`flex flex-col ${alignment} items-center gap-x-8`}>
            <div className="flex items-center">
              <Image
                src={src}
                alt={description}
                width={430}
                height={459}
                className="m-0"
              />
            </div>
            <ComponentRenderer
              document={text?.json as Document}
              classNames="w-full text-left"
            />
          </div>
        )
      },
    },
  }

  return (
    <div className="mt-8 w-full py-8 text-center">
      <ComponentRenderer
        document={data.json as Document}
        options={renderOptions}
        classNames="content-container flex-col space-y-6 px-8 md:prose-ol:px-32 md:prose-ol:pb-8"
      />
    </div>
  )
}

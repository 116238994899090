import { BLOCKS } from '@contentful/rich-text-types'
import type { Document } from '@contentful/rich-text-types'
import type { Options } from '@contentful/rich-text-react-renderer'
import { useFragment } from '.gql/fragment-masking'
import type { FragmentType } from '.gql/fragment-masking'
import type { LinkNode } from 'src/lib/rich-text-render-helpers'
import {
  ComponentRenderer,
  createEntryMap,
  getImageConfig,
} from 'src/lib/rich-text-render-helpers'
import Image from 'next/image'
import { graphql } from '.gql/gql'
import { twJoin } from 'tailwind-merge'

export const TeacherSupportStatementBlock_Fragment = graphql(/* GraphQL */ `
  fragment TeacherSupportStatementBlock_Fragment on WritingLegendsMarketingSiteIndexPageTeacherSupportBlock {
    __typename
    json
    links {
      entries {
        block {
          sys {
            id
          }
          ... on ComposeComponentVideoAndAdjacentText {
            ...ComposeComponentVideoAndAdjacentText_Fragment
          }
          ... on ComposeComponentImageAndAdjacentText {
            ...ComposeComponentImageAndAdjacentText_Fragment
          }
        }
      }
    }
  }
`)

export type TeacherSupportBlock = FragmentType<
  typeof TeacherSupportStatementBlock_Fragment
>

interface TeacherSupportBlockProps {
  query: TeacherSupportBlock
}

export function TeacherSupportBlock({ query }: TeacherSupportBlockProps) {
  const data = useFragment(TeacherSupportStatementBlock_Fragment, query)
  const entryMap = createEntryMap(data.links.entries.block)

  const renderOptions: Options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node: LinkNode) => {
        const id = node.data?.target?.sys?.id ?? ''
        const entry = entryMap.get(id)

        if (entry?.__typename !== 'ComposeComponentImageAndAdjacentText') return

        const { alignment, description, text, src } = getImageConfig({
          query: entry,
        })

        return (
          <>
            <div
              className={twJoin(
                'flex flex-col items-center gap-x-8 pt-8',
                alignment,
              )}
            >
              <div className="flex items-center">
                <Image
                  alt={description}
                  height={1122}
                  width={800}
                  src={src}
                  className="m-0"
                />
              </div>

              <ComponentRenderer
                document={text?.json as Document}
                classNames="w-full text-left"
              />
            </div>
          </>
        )
      },
    },
  }

  return (
    <div className="md:prose-li:bulleted-list-coin py-16 prose-li:list-none">
      <ComponentRenderer
        document={data.json as Document}
        options={renderOptions}
        classNames="content-container flex-col px-8"
      />
    </div>
  )
}

import type { Options } from '@contentful/rich-text-react-renderer'
import { useFragment as getFragment } from '.gql/fragment-masking'
import type { FragmentType } from '.gql/fragment-masking'
import { BLOCKS } from '@contentful/rich-text-types'
import type { Document } from '@contentful/rich-text-types'
import type { LinkNode } from 'src/lib/rich-text-render-helpers'
import {
  ComponentRenderer,
  ComposeComponentVideoAndAdjacentText_Fragment,
  createEntryMap,
} from 'src/lib/rich-text-render-helpers'
import { graphql } from '.gql/gql'

export const VideoBlock_Fragment = graphql(/* GraphQL */ `
  fragment VideoBlock_Fragment on WritingLegendsMarketingSiteIndexPageVideoBlock {
    __typename
    json
    links {
      entries {
        block {
          sys {
            id
          }
          ... on ComposeComponentVideoAndAdjacentText {
            ...ComposeComponentVideoAndAdjacentText_Fragment
          }
          ... on ComposeComponentImageAndAdjacentText {
            ...ComposeComponentImageAndAdjacentText_Fragment
          }
        }
      }
    }
  }
`)

export type VideoBlockQuery = FragmentType<typeof VideoBlock_Fragment>
interface FreeTrialBlockProps {
  query: VideoBlockQuery
}

export function VideoCTA({ query }: FreeTrialBlockProps) {
  const data = getFragment(VideoBlock_Fragment, query)
  const entryMap = createEntryMap(data.links.entries.block)

  const renderOptions: Options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node: LinkNode) => {
        const id = node.data?.target?.sys?.id ?? ''
        const entry = entryMap.get(id)

        if (entry?.__typename !== 'ComposeComponentVideoAndAdjacentText') return
        const { video, description } = getFragment(
          ComposeComponentVideoAndAdjacentText_Fragment,
          entry,
        )

        return (
          <div className="content-container flex-col items-center gap-8 px-6 prose-h3:text-2xl max-md:px-4 md:flex-row">
            <ComponentRenderer
              document={description?.json as Document}
              classNames="prose-a:cta-link space-y-10 md:w-7/12"
            />

            <div className="md:w-5/12">
              <video
                className="rounded-md border-2 border-solid border-wl-orange-500 drop-shadow-md"
                controls={true}
                src={video?.url ?? ''}
              />
            </div>
          </div>
        )
      },
    },
  }

  return (
    <ComponentRenderer
      document={data.json as Document}
      options={renderOptions}
      classNames="bg-wl-blue-regatta-700 py-6 prose-headings:text-shadow-md prose-h1:text-white prose-h3:text-wl-gold-500"
    />
  )
}
